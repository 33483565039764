<template>
  <v-data-table
    :headers="headers"
    :items="filteredRows"
    :hide-default-footer="true"
    disable-pagination
    :loading="loading"
    mobile-breakpoint="0"
    disable-sort
    v-if="!loading"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!--        <c-exam-result-lesson-group-->
        <!--            v-model="examResultLessonGroupId" dense class="mt-5"-->
        <!--            @change="getDataFromApi" clearable-->
        <!--        >-->
        <!--        </c-exam-result-lesson-group>-->
        <v-select
          v-model="lessonBranchId"
          :item-text="(item) => item.Name"
          :item-value="(item) => item.Id"
          :items="lessonBranches"
          label="Ders Branşı"
          clearable
          hide-details
          outlined
          dense
          @change="filterRows()"
        >
        </v-select>
      </v-toolbar>
      <v-toolbar flat>
        <v-range-slider
          label="Kazanım Başarı Yüzdesi"
          v-model="scyRange"
          max="100"
          min="0"
          :thumb-size="20"
          thumb-label="always"
          step="1"
          class="mt-5"
          :loading="loadingProgressBar"
          @change="filterRows()"
        ></v-range-slider>
      </v-toolbar>
    </template>
    <template v-slot:[`item.SuccessRate`]="{ item }">
      <v-progress-linear
        :color="getColor(item.SuccessRate)"
        :value="item.SuccessRate > 0 ? item.SuccessRate : 0"
        height="25"
        readonly
        rounded
      >
        <strong>{{ Math.ceil(item.SuccessRate > 0 ? item.SuccessRate : 0) }}%</strong>
      </v-progress-linear>
    </template>
    <template v-slot:[`item.QuestionCount`]="{ item }">
      {{ item.CorrectAnswerCount + item.WrongAnswerCount + item.EmptyAnswerCount }}
    </template>
    <template v-slot:[`item.TotalNet`]="{ item }">
      {{ item.TotalNet | toNumber }}
    </template>
  </v-data-table>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'combined-result-achievement-report',
  props: ['exams', 'assessmentPointCalculationTypeId'],
  data() {
    return {
      scyRange: [0, 100],
      search: '',
      rows: [],
      loading: true,
      loadingProgressBar: false,
      headers: [
        { text: 'Ders Branşı', value: 'LessonBranchName' },
        { text: 'Puan Tipi', value: 'AssessmentPointCalculationTypeName' },
        { text: 'Kazanım', value: 'GroupingEntityName' },
        { text: 'Toplam', value: 'TotalAnswerCount' },
        { text: 'Doğru', value: 'CorrectAnswerCount' },
        { text: 'Yanlış', value: 'WrongAnswerCount' },
        { text: 'Boş', value: 'EmptyAnswerCount' },
        { text: 'Net', value: 'TotalNet' },
        { text: 'Başarı', value: 'SuccessRate' ,width:100}
      ],
      groupingPropertyName: 'Achievement',
      examResultLessonGroupId: null,
      filteredRows: [],
      lessonBranchId: null,
      lessonBranches: []
    }
  },
  watch: {
    exams: {
      handler: function() {
        this.getDataFromApi()
      },
      deep: true
    },
    assessmentPointCalculationTypeId() {
      this.getDataFromApi()
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getColor(successRate) {
      if (successRate > 75) return 'green'
      else if (successRate > 40) return 'yellow'
      else return 'red'
    },

    filterRows() {
      this.loadingProgressBar = true
      setTimeout(() => {
        this.filteredRows = this.rows.filter((d) =>
          this.scyRange[1] >= d.SuccessRate &&
          (this.scyRange[0] == 0 || this.scyRange[0] <= d.SuccessRate) &&
          (this.lessonBranchId == null || this.lessonBranchId == d.LessonBranchId))

        this.loadingProgressBar = false
      }, 500)
    },

    getDataFromApi() {
      if (this.exams && this.exams.length > 0) {
        this.loading = true
        ApiService.setHeader()
        ApiService.post('api/CombinedExamReport/SubjectReport', {
          ExamIds: this.exams.map((s) => {
            return s.Id
          }),
          ExamResultLessonGroupId: this.examResultLessonGroupId,
          GroupingPropertyName: this.groupingPropertyName,
          AssessmentPointCalculationTypeId: this.assessmentPointCalculationTypeId
        }).then((data) => {
          this.rows = data.data

          this.lessonBranches = data.data.map((s) => {
            return {
              Id: s.LessonBranchId,
              Name: s.LessonBranchName
            }
          })

          this.filterRows()
          this.loading = false
        }).catch(({ response }) => {
          ApiService.showError(response)
          this.loading = false
        })
      }
    }
  }
}
</script>
