<template>
  <v-sheet outlined rounded>
    <v-simple-table class="table-bordered table-striped " v-if="!loading">
      <template v-slot:default>
        <thead>
        <tr>
          <th class='text-left font-size-h6'>Ders Grubu</th>
          <th class='text-center font-size-h6'>Soru Sayısı</th>
          <th class='text-center font-size-h6'>Doğru Sayısı</th>
          <th class='text-center font-size-h6'>Yanlış Sayısı</th>
          <th class='text-center font-size-h6'>Boş Sayısı</th>
          <th class='text-center font-size-h6'>Net</th>
          <th class='text-center font-size-h6'>Net Yüzdesi</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for='(row, idx) in rows' :key='idx'>
          <td class="font-size-h6">{{ row.ExamResultLessonGroupName }}</td>
          <td class='text-center font-size-h6'>{{ row.QuestionCount }}</td>
          <td class='text-center font-size-h6'>{{ row.CorrectAnswerCount }}</td>
          <td class='text-center font-size-h6'>{{ row.WrongAnswerCount }}</td>
          <td class='text-center font-size-h6'>{{ row.EmptyAnswerCount }}</td>
          <td class='text-center font-size-h6'>{{ row.Net | toNumber }}</td>
          <td class='text-center font-size-h6'>%{{ row.NetPercentage | toNumber }}</td>
        </tr>
        <tr>
          <td class='text-left font-size-h6'><strong>TOPLAM</strong></td>
          <td class='text-center font-size-h6'><strong>{{ rows.reduce((acc, row) => acc + row.QuestionCount, 0) }}</strong></td>
          <td class='text-center font-size-h6'><strong>{{ rows.reduce((acc, row) => acc + row.CorrectAnswerCount, 0) }}</strong></td>
          <td class='text-center font-size-h6'><strong>{{ rows.reduce((acc, row) => acc + row.WrongAnswerCount, 0) }}</strong></td>
          <td class='text-center font-size-h6'><strong>{{ rows.reduce((acc, row) => acc + row.EmptyAnswerCount, 0) }}</strong></td>
          <td class='text-center font-size-h6'><strong>{{ rows.reduce((acc, row) => acc + row.Net, 0) | toNumber }}</strong></td>
          <td class='text-center font-size-h6'><strong>%{{ rows.reduce((acc, row) => acc + row.Net, 0) / rows.reduce((acc, row) => acc + row.QuestionCount, 0) * 100 | toNumber }}</strong></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else class="d-flex justify-content-center">
      <v-progress-circular indeterminate color="deep-orange" class="m-5"></v-progress-circular>
    </div>
  </v-sheet>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'all-exam-results',
  props: ['examIds', 'assessmentPointCalculationTypeId'],
  data() {
    return {
      loading: false,
      rows: [],
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  watch: {
    examIds() {
      this.getDataFromApi()
    },
    assessmentPointCalculationTypeId() {
      this.getDataFromApi()
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/CombinedExamResultReport/ExamStudentResults', {ExamIds: this.examIds, AssessmentPointCalculationTypeId: this.assessmentPointCalculationTypeId})
          .then((data) => {
            this.rows = data.data
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>
