<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="d-flex justify-content-center">
            SINAV RAPORLARI
          </v-card-title>
          <v-card-text class="px-1">
            <v-container fluid>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mobile ? 6 : 4">
                  <c-date-picker v-model="examFilter.StartDate" label="Başlangıç Tarihi"
                                 @input="getExamsFromApi" hide-details @keydown.enter="getExamsFromApi">
                  </c-date-picker>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mobile ? 6 : 4">
                  <c-date-picker v-model="examFilter.EndDate" label="Bitiş Tarihi"
                                 @input="getExamsFromApi" hide-details @keydown.enter="getExamsFromApi">
                  </c-date-picker>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                  <c-exam-kind v-model="examFilter.ExamKindId" @change="getExamsFromApi" :for-student="true"></c-exam-kind>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                      v-model="selectedExamIds"
                      :item-text="(item) => item.ExamName + ' [' + moment(item.ExamStartDate) + ']'"
                      :item-value="(item) => item.ExamId"
                      :items="exams"
                      label="Öğrencinin Katıldığı Sınavlar"
                      multiple
                      clearable
                      outlined
                      dense
                      hide-details
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small outlined color="primary" class="mt-1 ml-1">{{ item.ExamName }}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn-toggle v-model="selectedExamOption" color="primary" dense @change="updateSelected">
                    <v-btn v-for="item in examOptions" :key="item.value">
                      {{ item.text }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col :class="$vuetify.breakpoint.mobile ? '':  'd-flex justify-content-end'">
                  <v-btn-toggle v-model="assessmentPointCalculationType" color="primary" dense v-if="assessmentPointCalculationTypes.length > 0">
                    <v-btn v-for="item in assessmentPointCalculationTypes" :key="item.value">
                      {{ item.text }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-show="selectedExamIds && selectedExamIds.length > 0">
        <v-card>
          <v-tabs
              v-model="tabSelector"
              background-color="success"
              next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline"
              show-arrows
              grow
              dark
          >
            <v-tab href="#tab-0"> SONUÇLAR</v-tab>
            <v-tab href="#tab-1"> NET ANALİZİ</v-tab>
            <v-tab href="#tab-2"> KONU ANALİZİ</v-tab>
            <v-tab href="#tab-3"> KAZANIM ANALİZİ</v-tab>
            <v-tab href="#tab-4"> PUAN ANALİZİ</v-tab>
            <v-tab href="#tab-5"> KATILMADIĞI SINAVLAR</v-tab>
          </v-tabs>
          <v-tabs-items touchless v-model="tabSelector">
            <v-tab-item :value="'tab-0'">
              <all-exam-results :exam-ids="selectedExamIds" :assessment-point-calculation-type-id="assessmentPointCalculationTypeId" :key="compKey"></all-exam-results>
            </v-tab-item>
            <v-tab-item :value="'tab-1'">
              <CombinedResultNetAnalysisWidget :exams="selectedExams" :key="compKey"/>
            </v-tab-item>
            <v-tab-item :value="'tab-2'">
              <CombinedResultSubjectReportWidget :exams="selectedExams" :assessment-point-calculation-type-id="assessmentPointCalculationTypeId" :key="compKey"/>
            </v-tab-item>
            <v-tab-item :value="'tab-3'">
              <combined-result-achievement-report :exams="selectedExams" :assessment-point-calculation-type-id="assessmentPointCalculationTypeId" :key="compKey"/>
            </v-tab-item>
            <v-tab-item :value="'tab-4'">
              <CombinedResultPointAnalysisWidget :exams="selectedExams" :key="compKey"/>
            </v-tab-item>
            <v-tab-item :value="'tab-5'">
              <AbsentExamsWidget></AbsentExamsWidget>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col v-show="!selectedExamIds || selectedExamIds.length == 0">
        <v-alert class="my-3" type="info">Raporları görmek için sınav seçiniz</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import CombinedResultSubjectReportWidget from '@/view/components/ExamReport/CombinedResultSubjectReportWidget.vue'
import CombinedResultNetAnalysisWidget from '@/view/components/ExamReport/CombinedResultNetAnalysisWidget.vue'
import CombinedResultPointAnalysisWidget from '@/view/components/ExamReport/CombinedResultPointAnalysisWidget.vue'
import AbsentExamsWidget from '@/view/components/ExamReport/AbsentExamsWidget.vue'
import moment from 'moment'
import AllExamResults from '@/view/components/ExamReport/AllExamResults.vue'
import CombinedResultAchievementReport from '@/view/components/ExamReport/CombinedResultAchievementReportWidget.vue'

export default {
  name: 'combined-result',
  components: {
    CombinedResultAchievementReport,
    AllExamResults,
    CombinedResultSubjectReportWidget,
    CombinedResultNetAnalysisWidget,
    CombinedResultPointAnalysisWidget,
    AbsentExamsWidget
  },
  data() {
    return {
      exams: [],
      tabSelector: 'tab-0',
      selectedExamIds: [],
      examFilter: {
        StartDate: moment().subtract(6, 'months').format(),
        EndDate: moment().format(),
        ExamKindId: null
      },
      loading: true,
      dialog: false,
      search: '',
      reveal: false,
      rows: [],
      selectAll: false,
      options: {},
      headers: [
        {text: '#', value: 'Id'},
        {text: 'Öğrenci Adı', value: 'FullName'},
        {text: 'İşlemler', value: 'actions', sortable: false}
      ],
      examOptions: [
        {text: 'Son 5 Sınav', value: 0},
        {text: 'Son 10 Sınav', value: 1},
        {text: 'Tüm Sınavlar', value: 2}
      ],
      assessmentPointCalculationTypes: [
        {text: 'Tüm Puan Tipleri', value: 0}
      ],
      selectedExamOption: null,
      assessmentPointCalculationType: 0,
      compKey: 0
    }
  },
  computed: {
    selectedExams() {
      return this.exams.filter((s) => this.selectedExamIds.indexOf(s.ExamId) > -1)
          .map((s) => {
            return {
              Id: s.ExamId,
              Name: s.ExamName,
              StartDate: s.ExamStartDate
            }
          })
    },
    assessmentPointCalculationTypeId() {
      if (this.assessmentPointCalculationType == null || this.assessmentPointCalculationTypes.length == 0) {
        return 0
      }

      return this.assessmentPointCalculationTypes[this.assessmentPointCalculationType].value
    }
  },
  watch: {
    assessmentPointCalculationType() {
      this.updateSelected()

      this.compKey += 1
    }
  },
  mounted() {
    this.getExamsFromApi()
  },
  methods: {
    moment: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    getExamsFromApi() {
      this.exams = []
      this.loading = true
      this.selectedExamIds = []
      ApiService.setHeader()
      ApiService.post('api/CombinedExamResultReport/ExamStudents', {
        sortBy: ['ExamStartDate'],
        descending: [true],
        rowsPerPage: -1,
        page: 1,
        searchVal: this.search,
        Query: {
          StartDate: this.examFilter.StartDate,
          EndDate: this.examFilter.EndDate,
          ExamKindId: this.examFilter.ExamKindId,
          OnlyAttended: true
        }
      }).then((data) => {
        this.exams = data.data.Results
        if (this.selectedExamOption == null) 
          this.selectedExamOption = 0

        this.getAssessmentPointCalculationTypes()
        this.updateSelected()
        this.compKey += 1
        this.loading = false
      }).catch(({response}) => {
        ApiService.showError(response)
        this.loading = false
      })
    },

    arrayOnlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },

    updateSelected() {
      if (this.selectedExamOption == 0) {
        this.selectedExamIds = this.exams
            .filter(s => this.assessmentPointCalculationTypeId > 0 ? s.AssessmentPointCalculationTypeIds.indexOf(this.assessmentPointCalculationTypeId) > -1 : true)
            .sort((a, b) => b.ExamId - a.ExamId)
            .slice(0, this.exams.length > 5 ? 5 : this.exams.length)
            .map((s) => s.ExamId)
      } else if (this.selectedExamOption == 1) {
        this.selectedExamIds = this.exams
            .filter(s => this.assessmentPointCalculationTypeId > 0 ? s.AssessmentPointCalculationTypeIds.indexOf(this.assessmentPointCalculationTypeId) > -1 : true)
            .sort((a, b) => b.ExamId - a.ExamId)
            .slice(0, this.exams.length > 10 ? 10 : this.exams.length)
            .map((s) => s.ExamId)
      } else if (this.selectedExamOption == 2) {
        this.selectedExamIds = this.exams
            .filter(s => this.assessmentPointCalculationTypeId > 0 ? s.AssessmentPointCalculationTypeIds.indexOf(this.assessmentPointCalculationTypeId) > -1 : true)
            .sort((a, b) => b.ExamId - a.ExamId)
            .map((s) => s.ExamId)
      }
    },

    getAssessmentPointCalculationTypes() {
      if (this.selectedExamIds.length == 0)
        return
      
      this.assessmentPointCalculationTypes = []
      ApiService.setHeader()
      ApiService.post('api/AssessmentEvaluations/AssessmentPointCalculationType', {
        sortBy: ['Name'],
        descending: [true],
        rowsPerPage: -1,
        page: 1,
        Query: {
          ExamIds: this.selectedExamIds
        }
      }).then((data) => {
        if (data.data.Results.length < 2)
          return

        this.assessmentPointCalculationTypes = [{text: 'Tüm Puan Tipleri', value: 0}]
        data.data.Results.map((x) => {
          this.assessmentPointCalculationTypes.push({text: x.Name, value: x.Id})
        })
      })
    }
  }
}
</script>
