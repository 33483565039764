<template>
  <v-data-table
      :headers="headers"
      :items="filteredrows"
      :hide-default-footer="true"
      disable-pagination
      :loading="loading"
      mobile-breakpoint="0"
      class="elevation-1"
      show-expand
      item-key="CustomId"
      single-expand
      disabe-sort
      v-if="!loading"
  >
    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" style="padding: 0">
        <v-card flat>
          <SubjectReportListByStudentAndAchievement
              :ExamIds="exams.map((exam) => exam.Id)"
              :GroupingPropertyIds="[item.SubjectId]"
              :GroupingPropertyName="GroupingPropertyName"
              :assessment-point-calculation-type-id="item.AssessmentPointCalculationTypeId"
          />
        </v-card>
      </td>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-select
            class="mt-3"
            v-model="LessonId"
            :item-text="(item) => item.Name"
            :item-value="(item) => item.Id"
            :items="lessons"
            @change="getDataFromApi"
            label="Ders"
            clearable
            hide-details
            outlined
            dense
        >
        </v-select>
      </v-toolbar>
      <v-toolbar flat>
        <v-range-slider
            label="Konu Başarı Yüzdesi"
            v-model="scyRange"
            max="100"
            min="0"
            :thumb-size="20"
            thumb-label="always"
            step="1"
            class="mt-5"
        ></v-range-slider>
      </v-toolbar>
    </template>
    <template v-slot:[`item.SuccessRate`]="{ item }">
      <v-progress-linear
          :color="getColor(item.SuccessRate)"
          :value="item.SuccessRate > 0 ? item.SuccessRate : 0"
          height="25"
          readonly
          rounded
      >
        <strong>{{ Math.ceil(item.SuccessRate > 0 ? item.SuccessRate : 0) }}%</strong>
      </v-progress-linear>
    </template>
    <template v-slot:[`item.QuestionCount`]="{ item }">
      {{ item.CorrectAnswerCount + item.WrongAnswerCount + item.EmptyAnswerCount }}
    </template>
    <template v-slot:[`item.HomeworkQuestionCount`]="{ item }">
      <div class="text-center">
        <v-progress-circular
            v-if="loadingHomework"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <span v-if="item.TestCount > 0">
          <v-tooltip bottom :color="getColor((item.CompletedTestCount / item.TestCount) * 100)">
                <template v-slot:activator="{ on, attrs }">
                  <v-progress-linear
                      :color="getColor((item.CompletedTestCount / item.TestCount) * 100)"
                      :value="(item.CompletedTestCount / item.TestCount) * 100"
                      height="25"
                      readonly
                      style="min-width: 50px"
                      v-bind="attrs"
                      v-on="on"
                      rounded
                  >
                    <strong
                    >{{ Math.ceil((item.CompletedTestCount / item.TestCount) * 100) }}%
                      <a
                          target="_blank"
                          :href="
                          '/odev-karnesi/' +
                          '?LessonId=' +
                          item.LessonId +
                          '&SubjectId=' +
                          item.SubjectId +
                          '&ParentSubjectId=' +
                          item.ParentSubjectId
                        "
                      >
                        <v-icon small v-b-tooltip.hover.right="'Ödev Karnesine Git'">
                          mdi-arrow-right-bold
                        </v-icon>
                      </a>
                    </strong>
                  </v-progress-linear>
                </template>
                <span style="color: black"
                >İşaretlenen soru sayısı: {{ item.CompletedQuestionCount }} <br/>
                  İşaretlenmeyen soru sayısı:
                  {{ item.NotCompletedQuestionCount }}
                </span>
              </v-tooltip>
        </span>
      </div>
    </template>
    <template v-slot:[`item.HomeworkSCY`]="{ item }">
      <div class="text-center">
        <v-progress-circular
            v-if="loadingHomework"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <span v-if="item.SCY">
          <v-progress-linear
              :color="getColor(item.SCY)"
              :value="item.SCY"
              height="25"
              readonly
              rounded
          >
            <strong>{{ Math.ceil(item.SCY) }}%</strong>
          </v-progress-linear>
        </span>
      </div>
    </template>
    <template v-slot:no-data>
      <span>VERİ BULUNAMADI</span>
    </template>
  </v-data-table>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import SubjectReportListByStudentAndAchievement from '@/view/components/ExamReport/SubjectReportListByStudentAndAchievement.vue'

export default {
  name: 'combined-result-exams',
  components: {SubjectReportListByStudentAndAchievement},
  props: ['exams','assessmentPointCalculationTypeId'],
  data() {
    return {
      scyRange: [0, 100],
      search: '',
      LessonId: null,
      rows: [],
      lessons: [],
      loading: true,
      options: {sortBy: ['LessonName']},
      headers: [
        {text: 'Ders Branşı', value: 'LessonName', sortable: false},
        {text: 'Puan Tipi', value: 'AssessmentPointCalculationTypeName', sortable: false},
        {text: 'Konu', value: 'SubjectName', sortable: false},
        {text: 'Soru Sayısı', value: 'QuestionCount', sortable: false},
        {text: 'Doğru', value: 'CorrectAnswerCount', sortable: false},
        {text: 'Yanlış', value: 'WrongAnswerCount', sortable: false},
        {text: 'Boş', value: 'EmptyAnswerCount', sortable: false},
        {text: 'Net', value: 'TotalNet', sortable: false},
        {text: 'Konu Başarı Yüzdesi', value: 'SuccessRate', sortable: false},
        {text: 'Soru İşaretleme Oranı', value: 'HomeworkQuestionCount', sortable: false},
        {text: 'Doğru Cevaplama Oranı', value: 'HomeworkSCY', sortable: false}
      ],
      loadingHomework: true,
      GroupingPropertyName: 'Subject',
    }
  },
  computed: {
    filteredrows() {
      var min = this.scyRange[0]
      var max = this.scyRange[1]
      return this.rows.filter((d) => max >= d.SuccessRate && (min == 0 || min <= d.SuccessRate) && (this.LessonId == null || this.LessonId == d.LessonId))
    }
  },
  watch: {
    exams() {
      this.LessonId = null
      this.getDataFromApi()
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    },
    assessmentPointCalculationTypeId() {
      this.getDataFromApi()
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getColor(successRate) {
      if (successRate > 75) return 'green'
      else if (successRate > 40) return 'yellow'
      else return 'red'
    },
    
    getDataFromApi() {
      if (this.exams && this.exams.length > 0) {
        this.loading = true
        ApiService.setHeader()
        ApiService.post('api/CombinedExamResultReport/SubjectAnalysis/SubjectReport', {
          ExamIds: this.exams.map((s) => {return s.Id}),
          LessonId: this.LessonId ?? 0,
          MinSuccessRate: this.scyRange[0],
          MaxSuccessRate: this.scyRange[1],
          StudentId: 0,
          AssessmentPointCalculationTypeId: this.assessmentPointCalculationTypeId
        }).then((data) => {
          this.rows = data.data
          if (this.LessonId == null || this.LessonId == 0)
            this.lessons = data.data.map((s) => {
              return {
                Id: s.LessonId,
                Name: s.LessonName
              }
            })
          this.loading = false
          this.getHomeworkDataFromApi()
        }).catch(({response}) => {
          ApiService.showError(response)
          this.loading = false
        })
      }
    },
    getHomeworkDataFromApi() {
      this.loadingHomework = true
      ApiService.setHeader()
      ApiService.post('api/Homework/reports/StudentSubjectReport', {
        SubjectIds: this.rows.map((s) => s.SubjectId),
      }).then((data) => {
        if (data.data.length == 0) {
          this.loadingHomework = false
          return
        }

        for (let index = 0; index < data.data.length; index++) {
          const element = data.data[index]
          const elements = this.rows.filter((r) => r.SubjectId === element.SubjectId)
          
          if (elements.length === 0) continue
          elements.forEach((f) => {
            f.CompletedTestCount = element.CompletedTestCount
            f.NotCompletedTestCount = element.NotCompletedTestCount
            f.TestCount = element.TestCount
            f.CompletedQuestionCount = element.CompletedQuestionCount
            f.NotCompletedQuestionCount = element.NotCompletedQuestionCount
            f.SCY = Math.round(element.SCY)
          })
        }
        this.loadingHomework = false
      }).catch(({response}) => {
        ApiService.showError(response)
        this.loadingHomework = false
      })
    }
  }
}
</script>
